var site = site || {};

site.device = {};
site.window = {};
site.owl = {};
site.nav = {};
site.sidebar = {};
site.hero = {};

site.device.isTouch = function() {
	try {
		document.createEvent("TouchEvent");
		return true;
	} catch (e) {
		return false;
	}
};

site.window.scrollPosition = 0;

// Shorthand to init owlcarousel
site.owl.init = function(id, options) {
	var $owl = $(id);
	if ($owl.length > 0) {
		$owl.addClass('owl-carousel');
		$owl.owlCarousel(options);
	}
};

// Init navigation scripts
site.nav.init = function() {
	// Add expand link to mobile menu
	$('.children > a', '#mainmenu ').prepend('<a class="expand" href="#"></a>');

	site.nav.bind();
};

// Bind events
site.nav.bind = function() {
	// Mobile menu toggle
	$('#mainmenuToggle').click(function(e) {
		var menuOpen = $('body').hasClass('mainmenu-open');
		if (!menuOpen) {
			site.window.scrollPosition = $(window).scrollTop();
		}
		site.nav.mobileToggle(e, menuOpen, site.window.scrollPosition);
		e.preventDefault();
	});

	// Expand in mobile menu
	$('.expand', '#mainmenu').bind('click', function(e) {
		e.preventDefault();
		$(this).parents('li').toggleClass('open');
		return false;
	});

	$('li.level1.children', '#mainmenu').hover(function() {
		if (site.device.isTouch() === false) {
			$(this).addClass('open');
		}
	}, function() {
		if (site.device.isTouch() === false) {
			$(this).removeClass('open');
		}
	});

	$('li.level1.children > a', '#mainmenu').bind('click', function(e) {
		var $li = $(this).parents('li');
		if (site.device.isTouch() && !$li.hasClass('open') && !$('body').hasClass('mainmenu-open')) {
			e.preventDefault();
			$('li.level1', '#mainmenu').removeClass('open');
			$li.addClass('open');
		}
	});
};

// Toggle mobile menu
site.nav.mobileToggle = function(e, menuOpen, scrollPosition) {
	$('body').toggleClass('mainmenu-open');
	$(this).toggleClass('open');
	if (!menuOpen) {
		$(window).scrollTop(0);
	}
	else {
		$(window).scrollTop(scrollPosition);
	}
};

// Append language to mobilemenu
site.nav.appendLanguage = function() {
	var $divisions = $('.menu', '#divisions');
	var $menu = $('ul.mmLevel1', '#mMenu');

	var $html = $('<li class="ele-language si level1"></li>');
	$divisions.find('li').each(function() {
		var $li = $(this).clone();
		$html.append($li.find('a'));
	});

	$menu.append($html);
};


// Run all the inits \o/
$(function() {
	site.nav.init();
	site.owl.init('#hero > ul', {
		loop: true,
		dots: true,
		items: 1,
		autoplay: true,
		autoplayTimeout: 10000,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut'
	});

	//$('table.table1 tr:first-child').addClass('title');
	//$('table.table1 tr:nth-child(2)').addClass('head');
	//$('table.table1 tr:even').addClass('altRow2');
	//$('table.table1 tr:odd').addClass('altRow1');

	//(function() {
	//	var columns = $('table.table2 td').length;
	//	var width = Math.floor(100 / columns);
	//	$('table.table2').each(function() {
	//		$('table.table2 td').css('width', width + '%');
	//	});
	//})();

	function modalRemove() {
		$('#modal-overlay').remove();
		$('#modal-window').remove();
	}

	$('a.modal').bind('click', function(e) {
		e.preventDefault();
		var $body = $('body');
		var $this = $(this);
		var href = $this.attr('href');
		var overlay = $('<div>').attr('id', 'modal-overlay').bind('click', modalRemove);
		$body.append(overlay);
		var div = $('<div>').attr('id', 'modal-window').load(href, function() {
			$('<div>').attr('id', 'modal-title').html($this.attr('title')).prependTo(div);
			$('<a>').attr('id', 'modal-close').bind('click', modalRemove).appendTo(div);
		});
		$body.append(div);

		return false;
	});

	// Responsive vimeo player
	//var $i = $('iframe', '#center');
	//if ($i.length > 0 && $i.attr('src').indexOf('player.vimeo.com') != -1) {
	//	$i.wrap('<div class="vimeoPlayer"></div>');
	//}

	// Responsive tables
	$('table.table1').wrap('<div class="responsiveTable"></div>');

	// Touch login
	$('.login').bind('click', function(e) {
		e.preventDefault();
		window.location = '/moya';
	});

	// Reorder language menu based on selected
	// Move is to the top
	$li = $('.selected', '#divisions');
	$ul = $('.menu', '#divisions');
	$ul.prepend($li);
});
