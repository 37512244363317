sjodir = {};
sjodir.chart = {};
sjodir.chart.url = function (action, codename, timestamp) {
	const prefix = '/_/is/sjodir/chart' + '/' + action + '/' + codename;
	const postfix = timestamp ? '?timestamp=' + timestamp : '';
	return  prefix + postfix;
};
sjodir.chart.gchartLoaded = false;
sjodir.chart.chartLoadFinished = false;
sjodir.chart.load = function ($container) {
	if (!sjodir.chart.gchartLoaded) {
		sjodir.chart.gchartLoaded = true;
		google.charts.load("current", {packages:["corechart"]});
	}
	google.charts.setOnLoadCallback(function () {
		var url = sjodir.chart.url($container.data('action'), $container.data('id'), $container.data('timestamp'));
		$.get(url, function (response) {
			var data = google.visualization.arrayToDataTable(response.data);
			var options = response.options;
			if (response.dateUpdated != null) {
				var $title = $container.prevAll('h2:first');
				$title.html($title.html() + ' ' + response.dateUpdated);
			}
			var chart = null;
			switch (response.type) {
				case 'piechart':
					chart = new google.visualization.PieChart($container[0]);
					break;
				case 'linechart':
					chart = new google.visualization.LineChart($container[0]);
					break;
				case 'column':
					chart = new google.visualization.ColumnChart($container[0]);
					break;
				case 'bar':
					chart = new google.visualization.BarChart($container[0]);
					break;
				default:
					alert("No such chart: " + response.type);
					return;
			}
			// Draw the chart on screen
			chart.draw(data, options);

			// Used for resizing
			$container.data('chart-data', data);
			$container.data('chart-options', options);
			$container.data('chart', chart);
			sjodir.chart.chartLoadFinished = true;
		});
	});
};

sjodir.table = {};
sjodir.table.init = function (element) {
	var tmplTable = element.querySelector('template.table');
	var tmplRow = element.querySelector('template.row');
	if (!tmplTable || !tmplRow) {
		return;
	}

	var url = sjodir.chart.url(element.dataset.action, element.dataset.id);
	fetch(url)
		.then(function (response) {
			return response.json();
		})
		.then(function (response) {
			sjodir.table.render(element, tmplTable, tmplRow, response);
		});
};
sjodir.table.render = function (element, tmplTable, tmplRow, response) {
	if (!response || !response.assets) {
		return;
	}
	var wrap = document.createElement('div');
	element.appendChild(wrap);
	wrap.innerHTML = sjodir.table.renderTemplate(tmplTable, {});
	var table = wrap.querySelector('table');
	if (!table) {
		return;
	}
	var items = '';
	var limit = element.dataset['limit'] || response.assets.length;
	response.assets.slice(0, limit).forEach(function (item) {
		items += sjodir.table.renderTemplate(tmplRow, {
			name: item.name,
			ticker: item.ticker,
			section: item.asset_section,
			weight: sjodir.table.formatWeight(item.weight)
		});
	});
	table.querySelector('tbody').innerHTML = items;
};
sjodir.table.formatWeight = function(w) {
	return w.toFixed(1) + '%';
};
sjodir.table.renderTemplate = function (tmpl, object) {
	return tmpl.innerHTML.replace(/\\?{([^{}]+)}/g, function (match, name) {
		if (match.charAt(0) === '\\') return match.slice(1);
		return (object[name] != null) ? object[name] : '';
	});
};

$(function() {
	var $sjodir = $('#sjodir');
	var $charts = $sjodir.find('.google-chart');
	$charts.each(function () {
		sjodir.chart.load($(this));
	});

	$sjodir.find('.sjodir-table').each(function() {
		sjodir.table.init(this);
	});

	// Redraw on resize
	$(window).resize(function(){
		$charts.each(function () {
			var $this = $(this);
			$this.data('chart').draw($this.data('chart-data'), $this.data('chart-options'));
		});
	});
});
